#white_bullet .swiper-pagination-bullet {
  opacity: 0.5;
  background-color: white;
}

#gray_bullet .swiper-pagination-bullet {
  opacity: 0.5;
  background-color: gray;
}

#white_bullet .swiper-pagination-bullet-active {
  opacity: 1;
  /* -webkit-transition: opacity 0.25s, background-color 0.25s, width 0.25s;
  -o-transition: opacity 0.25s, background-color 0.25s, width 0.25s; */
  transition: opacity 0.25s, background-color 0.25s, width 0.25s;
  /* -webkit-transition-delay: 0.25s, 0.25s, 0s;
  -o-transition-delay: 0.25s, 0.25s, 0s;
  transition-delay: 0.25s, 0.25s, 0s; */
}

#gray_bullet .swiper-pagination-bullet-active {
  opacity: 1;
  /* -webkit-transition: opacity 0.25s, background-color 0.25s, width 0.25s;
  -o-transition: opacity 0.25s, background-color 0.25s, width 0.25s; */
  transition: opacity 0.25s, background-color 0.25s, width 0.25s;
  /* -webkit-transition-delay: 0.25s, 0.25s, 0s;
  -o-transition-delay: 0.25s, 0.25s, 0s;
  transition-delay: 0.25s, 0.25s, 0s; */
}
.auto .swiper-slide {
  width: fit-content;
}

@media (min-width: 1280px) {
  #gray_bullet .swiper-pagination {
    display: none !important;
  }
}

/* @media (max-width: 768px) {
  .swiper-button-prev {
    display: none !important;
  }
  .swiper-button-next {
    display: none !important;
  }
}

.swiper-button-prev {
  @apply absolute -left-4 z-50 !important;
  background-image: url(../../assets/icons/icon-chevron-left.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.swiper-button-prev::after {
  display: none;
}

.swiper-button-next {
  background-image: url(../../assets/icons/icon-chevron-right.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.swiper-button-next::after {
  display: none;
} */
